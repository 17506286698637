import React from 'react';
import ArticleHeadingBlock from '../components/article-heading';
import {ArticleList} from '../components/article-list';
import LinkFade from "../../components/linkfade";
import {buildSeo} from "../../utils/seo";


/**
 * @typedef {{label: string, slug: string=}} Category
 * @typedef {Object} Article
 * @typedef {{categories:Category[], currentCategory:Category, headingArticle:Article,group:Article[],index:number,first:boolean,last:boolean,pageCount:number}} CategoryPageContext
 */

/**
 * Page heading render
 * Visible only on first page
 * @param article
 * @param isFirstPage boolean : Is first page (pager)
 * @returns {*}
 * @private
 */
const ArticleHeading = ({article, title, currentCategory, categories}) => {
    return <ArticleHeadingBlock title={title} article={article } categories={categories} currentCategory={currentCategory}/>;
};


/**
 * Render Category page
 * On first page show first article as Heading
 * List next articles in a list
 *
 * @param pageContext CategoryPageContext
 * @return {*}
 * @constructor
 */
const CategoryPage = ({pageContext}) => {
    const {
        group,
        index,
        first,
        //last,
        pageCount,
        headingArticle,
        currentCategory,
        categories,
        pathPrefix,
    } = pageContext;
    const previousUrl = `/${pathPrefix}/${
        index - 1 === 1 ? '' : (index - 1).toString()
    }`;
    const nextUrl = `/${pathPrefix}/${(index + 1).toString()}`;

    pageContext.title = currentCategory.meta.title.replace("{page}", index);
    pageContext.metaDescription = currentCategory.meta.description.replace("{page}", index);

    // TODO FIX layout data
    return (
        <div className={"page-content blog"}>
            <>
                {buildSeo({
                    metaTitle: pageContext.title + " - Blog de Loris Pinna",
                    title: pageContext.title,
                    description: pageContext.metaDescription
                })}
            </>
            <ArticleHeading categories={categories} currentCategory={currentCategory} title={currentCategory.meta.pageTitle} article={headingArticle} isFirstPage={first}/>
            <section className={"section"}>
                <div className={"container"}>
                    <ArticleList articles={group}/>
                    <div className={"stepper"}>
                        {
                            index > 1 && (
                                <LinkFade url={previousUrl}><div className={"step active"}>{index - 1}</div></LinkFade>
                            )
                        }
                        <div className={"step active selected"}>{index}</div>
                        {
                            pageCount > index && (
                                <LinkFade url={nextUrl}><div className={"step active"}>{index + 1}</div></LinkFade>
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CategoryPage;
