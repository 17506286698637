import React from "react"
import {Image} from "../../components/image";
import Button from "../../components/button";
import LinkFade from "../../components/linkfade";

class ArticleHeadingBlock extends React.Component {

    constructor(props, context) {
        super(props, context);
        this.article = props.article.node;
        this.node = props.article;
        this.title = props.title;
        this.categories  = props.categories;
        this.currentCategory = props.currentCategory;
        this.title = props.title;
    }

    render() {
        return (
            <>
                <section className={"container to-logo__blue"}>
                    <div className={"background-title-container background-title-container__left as-title as-marquee"}>
                        <div className={"background-title"}>
                            <span>Blog. Blog. Blog. Blog. Blog. Blog. Blog. Blog. Blog.&nbsp;</span>
                            <span>Blog. Blog. Blog. Blog. Blog. Blog. Blog. Blog. Blog.&nbsp;</span>
                        </div>
                    </div>
                    <h1 className={"h2 md:max-w-66"} dangerouslySetInnerHTML={{__html: this.title ?? "Mes actualités & astuces<br/>pour des sites géniaux"}}></h1>
                </section>

                <section className={"section blue-section py-16 to-logo__white"}>
                    <div className={"container"}>
                        <section className={"work no-work-bottom"}>
                            <div className={"work-thumb news-thumb"}>
                                <Image
                                    src={this.article.frontmatter.thumbnail}
                                    alt={this.article.frontmatter.title}
                                />
                            </div>
                            <div className={"work-content"}>
                                <div className={"mb-4"}>
                                    <div className={"tag"}>{this.article.frontmatter.date}</div>
                                    {this.article.fields.categories.map((catId) => {
                                        return (
                                            <div key={catId} className={"tag"}>#{catId}</div>
                                        )
                                    })}
                                </div>
                                <h3>{this.article.frontmatter.title}</h3>
                                <p>{this.article.frontmatter.description}</p>
                                <Button name={"Lire l'article"} link={"/" + this.article.fields.path}/>
                            </div>
                        </section>
                    </div>
                </section>
                <section>
                    <div className={"container mt-16"}>
                        <div className={"tag-row"}>
                            {this.categories.map(v => <div className={`tag ${v.label === this.currentCategory.label ? "selected": ""}` } key={v}>
                                <LinkFade url={"/blog/" + (v.slug ?? "")}>{v.label}</LinkFade>
                            </div> )}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ArticleHeadingBlock
