import React from "react"
import {graphql, useStaticQuery} from "gatsby"
import {Image} from "../../components/image";
import LinkFade from "../../components/linkfade";

/**
 * Define article content object properties:
 * @typedef {{title: string, thumbnail: string, date:Date, description:String, rawDate: Date, ogTitle: String?, ogDescription: String?, metaTitle: String?, metaDescription: String?}} ArticleContent
 */

/**
 * Define article page object properties:
 * @typedef {{path: string, slug: string}} ArticlePage
 */

/**
 * Define article node:
 * @typedef {{node: {frontmatter:ArticleContent,fields:ArticlePage}}} ArticleNode
 */

/**
 * Define a list element renderer (ie component)
 * @typedef ArticleListElementRenderer
 * @param articleNode {{node:ArticleNode}}
 * @return {*}
 */

/**
 *
 * @param articleNode {{node:ArticleNode}}
 * @return {*}
 * @constructor
 */
const ArticleCategoryListRenderer = ({ articleNode }) => {
    let article = articleNode.node
    return (
        <div className={"news"}>
            <LinkFade url={`/${article.fields.path}`}>
                <div className={"news-thumb"}>
                    <Image
                        src={article.frontmatter.thumbnail}
                        alt={article.frontmatter.title}
                    />
                    <div className={"news-thumb-tags"}>
                        <div className={"tag"}>{article.frontmatter.date}</div>
                    </div>
                </div>
                <h3>{article.frontmatter.title}</h3>
            </LinkFade>
            <p>{article.frontmatter.description}</p>
        </div>
    )
}

/**
 *
 * @param articles ArticleNode[]
 * @param elementRenderer ArticleCategoryListRenderer
 * @return {*}
 * @constructor
 */
export const ArticleList = ({
                                articles,
                                elementRenderer = ArticleCategoryListRenderer,
                            }) => {
    const ElementRenderer = elementRenderer
    return (
        <div id={"news"} className={"news-list"}>
            {articles.map(article => (
                <ElementRenderer key={article.node.fields.slug} articleNode={article} />
            ))}
        </div>
    )
}

//<ArticleList articles={data.allMarkdownRemark.edges.filter((articleNode) => articleSlugs.includes(articleNode.node.fields.slug) )} />

/**
 *
 * @param articles ArticleNode[]
 * @param elementRenderer ArticleCategoryListRenderer
 * @return {*}
 * @constructor
 */
export const ArticleListBySlugs = ({ articleSlugs,elementRenderer = ArticleCategoryListRenderer }) => {
    const data = useStaticQuery(blogArticles);

    return (
        <ArticleList articles={data.allMarkdownRemark.edges.filter((articleNode) => articleSlugs.includes(articleNode.node.fields.slug.replace(/\//g,"")))} />
    );
}

export const blogArticles = graphql`{
  allMarkdownRemark(
    filter: {fields: {type: {eq: "blog"}}}
    sort: { fields: [frontmatter___date], order: DESC } 
    limit: 1000
  ) {
    edges {
      node {
        excerpt
        fields {
          slug
          path
        }
        frontmatter {
          title
          date(formatString: "DD/MM/YY")
          thumbnail
          description
          author
        }
      }
    }
  }
}`
